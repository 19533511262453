<template>
  <div>
    ERROR 404
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "E404",
});
</script>

<style scoped></style>
